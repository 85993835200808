function getProvincePercentage(province?: string | number) {
  switch (province) {
    case "AB":
      return 0.15;
    case "BC":
      return 0.15;
    case "ON":
      return 0.15;
    case "NB":
      return 0.15;
    case "MN":
      return 0.17;
    case "SK":
      return 0.23;
    case "NS":
      return 0.19;
    case "PE":
      return 0.15;
    default:
      return 0.15;
  }
}

export function calculateCost(loan: number, province?: string | number) {
  const percentage = getProvincePercentage(province);
  return loan * percentage;
}

export const getCashbackAmount = (cost: number, cashback?: string) => {
  switch (cashback) {
    case "Elite":
      return cost * 0.05;
    case "Prestige":
      return cost * 0.1;
    case "VIP":
      return cost * 0.2;
    default:
      return 0;
  }
};

export function calculateTotalCost(cost: number, cashback?: string) {
  const cashbackAmount = getCashbackAmount(cost, cashback);
  return cost - cashbackAmount;
}
