import * as React from "react";

import { Layout, Statistics, StatisticsCard } from "@brainfinance/icash-component-library";
import { generateTitle } from "@helpers/generateTitle";
import { LayoutProps } from "@types";
import { STATS } from "./statistics-data";
// Images
import defaultImg from "@images/default.png";

export const StatisticsList = (props:LayoutProps) => (
  <Layout className="bg-interface-100 pt-[99px] md:pt-[66px] sm:pt-[39px]">
    <div className="text-center mb-[28px] max-w-[500px] mx-auto md:max-w-[300px] md:mb-[16px] sm:text-left sm:px-[22px] sm:max-w-full sm:mb-[5px]">
      {generateTitle({
          title: props.title,
          green: props.green,
      })}
    </div>
    <div className="text-center global--paragraph text-interface-300 max-w-[660px] mx-auto mb-[66px] md:mb-[33px] md:max-w-[540px] md:mx-auto sm:w-full sm:text-left sm:px-[22px] sm:mx-0 sm:mb-[16px]">
      {props.description}
    </div>
    <Statistics className="pb-[69px] md:pb-[66px] sm:pb-[49px]">
      {STATS.map((statistic, ndx) => (
        <StatisticsCard key={ndx} {...statistic} />
      ))}
    </Statistics>
  </Layout>
);

StatisticsList.defaultProps = {
  title: "A better way to get a cash advance",
  gray: "",
  green: "cash advance",
  description:
    "iCash is a licensed, direct lender offering quick loans to Canadians. With our 100% online application process, you receive your funds within 5 minutes of approval!",
  src: defaultImg,
}