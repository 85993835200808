import * as React from "react";
import { CompareTable, Layout, LayoutDescription, LayoutHeader, LayoutImage, LayoutTitle } from "@brainfinance/icash-component-library";
import { LayoutProps } from "@types";
// Images
import defaultImg from "@images/default.png";
import { generateTitle } from "@helpers/generateTitle";

export const CompareTableSection = (props:LayoutProps) => (
  <Layout {...props}>
    <LayoutHeader>
      <LayoutTitle>
        {generateTitle({
          title: props.title,
          gray: props.gray
        })}
      </LayoutTitle>
      <LayoutDescription>{props.description}</LayoutDescription>
      <LayoutImage src={props.src} placement="sm-left" />
    </LayoutHeader>
    <div className="flex flex-col w-full max-w-[1407px] justify-center mx-auto lg:px-[22px] sm:px-0 sm:pt-[4rem]">
      <CompareTable />
    </div>
  </Layout>
);

CompareTableSection.defaultProps = {
  title: "Compare our instant loans",
  gray: "instant loans",
  src: defaultImg,
  description:
    "There are many payday lenders out there, so how can you be sure that iCash is the right choice for you? After reading the reviews of our thousands of happy customers, take a look at the comparison chart below! The choice is simple!"
}