import * as React from "react";
import { graphql, navigate } from "gatsby";
import { getImage } from "gatsby-plugin-image";

// Components
import { PageLayout, SEO } from "@components/page-layout";
import { MainHeaderContainer } from "@containers/header";
import { StatisticsList } from "@containers/statistics";
import { PartnersSection } from "@containers/partners";
import { Testimonials } from "@containers/testimonials";
import { CustomerService } from "@containers/customer-service";
import { HowInstantLoansWork } from "@containers/how-instant-loans-work";
import { LoanCalculator } from "@containers/loan-calculator";
import { CompareTableSection } from "@containers/compare-table";
import { TrustPilotWidget } from "@containers/trust-pilot-widget";
import { Disclaimer } from "@containers/disclaimer";
import { ICashAdvantages } from "@containers/icash-advantages";

const InstantLoansTemplate = (props: any) => {
  const { contentfulInstantLoans }: any = props.data;

  return (
    <PageLayout>
      <SEO
        title={contentfulInstantLoans.metaTitle}
        description={contentfulInstantLoans.metaDescription.metaDescription}
      />
      <MainHeaderContainer
        description="Take care of whatever life throws at you. Get an Instant approval for up to $1,500. Fast e-Transfers within minutes of signing up.\n Available 24/7 accross Canada. Apply online or download our app to get started."
        subParagraph="Available 24/7 accross Canada. Apply online or download our app to get started."
        src={getImage(props.data.headerFile)?.images.fallback?.src}
        srcSet={getImage(props.data.headerFile)?.images.fallback?.srcSet || ""}
        bgColor="bg-[#FFCEE7]"
      >
        Trusted instant loans in Canada
      </MainHeaderContainer>
      <div className="relative z-10 mt-[-203px] md:mt-0 md:z-auto">
        <LoanCalculator />
      </div>
      <HowInstantLoansWork className="!mt-[132px] md:!mt-[88px] sm:!mt-[66px] !mb-[121px] md:!mb-[66px] sm:!mb-[44px]" />
      <Disclaimer />
      <div className="py-[44px] md:py-[22px]">
        <PartnersSection />
      </div>
      <TrustPilotWidget />
      <div className="mt-[88px] md:mt-[35px] sm:mt-[42px]">
        <ICashAdvantages
          onClick={() => navigate('/login')}
          buttonText="Start your application"
          fastApplicationImg={getImage(props.data.fastApplicationFile)?.images.fallback?.src}
          noDocumentImg={getImage(props.data.noDocumentFile)?.images.fallback?.src}
          noHiddenImg={getImage(props.data.noHiddenFile)?.images.fallback?.src}
          instantImg={getImage(props.data.instantFile)?.images.fallback?.src}
          eTransferImg={getImage(props.data.eTransferFile)?.images.fallback?.src}
          cashbackImg={getImage(props.data.cashbackFile)?.images.fallback?.src}
          fastApplicationImgSrcSet={getImage(props.data.fastApplicationFile)?.images.fallback?.srcSet || ""}
          noDocumentImgSrcSet={getImage(props.data.noDocumentFile)?.images.fallback?.srcSet || ""}
          noHiddenImgSrcSet={getImage(props.data.noHiddenFile)?.images.fallback?.srcSet || ""}
          instantImgSrcSet={getImage(props.data.instantFile)?.images.fallback?.srcSet || ""}
          eTransferImgSrcSet={getImage(props.data.eTransferFile)?.images.fallback?.srcSet || ""}
          cashbackImgSrcSet={getImage(props.data.cashbackFile)?.images.fallback?.srcSet || ""}
        />
      </div>
      <StatisticsList />
      <CompareTableSection />
      <CustomerService />
      <Testimonials />
    </PageLayout>
  );
};

export default InstantLoansTemplate;

export const pageQuery = graphql`
  query InstantLoansQuery($slug: String!) {
    contentfulInstantLoans(slug: { eq: $slug }) {
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      h1
      headerImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160, quality: 70)
        title
      }
      headerDescription {
        headerDescription
      }
      bigListTitle
      bigListDescription {
        bigListDescription
      }
      listItems {
        title
        description
      }
      bigListShowButton
      bigListButtonText
    }
    fastApplicationFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    noDocumentFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    noHiddenFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    instantFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    eTransferFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    cashbackFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
  }
`;