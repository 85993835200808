import React from "react";

export const STATS = [
  {
    amount: 421,
    unit: "K",
    children: (
      <>
        Satisfied customers
        <br /> since 2014
      </>
    )
  },
  {
    amount: 93,
    unit: "%",
    children: (
      <>
        Number of approved
        <br /> applications
      </>
    )
  },
  {
    amount: 4.9,
    unit: "out of 5",
    children: (
      <>
        Customers that would
        <br /> recommend us to their friends
      </>
    )
  },
  {
    isStar: true,
    children: <>Customer rating</>
  }
];