import * as React from "react";
import { navigate } from "gatsby";
import {
  CostSimulator,
  CostSimulatorLoan,
  CostSimulatorRadioGroup,
  CostSimulatorSummaryBanner,
  SelectInput,
  SummaryBox,
  Price,
  SummaryDivider,
  LabeledValue,
} from "@brainfinance/icash-component-library";
import { calculateCost, calculateTotalCost, getCashbackAmount } from "./helpers";

import defaultImg from "@images/default.png";

const PROVINCES = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "Britsh Columbia" },
  { value: "ON", label: "Ontario" },
  { value: "NB", label: "New Brunswich" },
  { value: "MN", label: "Manitoba" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NS", label: "Nova Scotia" },
  { value: "PE", label: "Prince Edward Island" }
];

export const LoanCalculator = () => {
  const [province, setProvince] = React.useState<string | number | undefined>("BC");
  const [payment, setPayment] = React.useState<number | undefined>(undefined);
  const [cashback, setCashback] = React.useState<string | undefined>(undefined);
  const [loan, setLoan] = React.useState<number | undefined>(500);
  const [cost, setCost] = React.useState<number>(85.5);

  React.useEffect(() => {
    if (loan) {
      setCost(calculateCost(loan, province));
    }
  }, [loan, province]);

  return (
    <CostSimulator
      onApply={() => navigate("/login")}
      title="Customize your instant loan"
      timeText="Receive your money by"
    >
      <div className="cost-simulator--box-container mr-[141px] lg:mr-[70px] sm:mx-auto">
        <div className="flex flex-col justify-start items-start">
          <CostSimulatorLoan defaultValue={500} onChange={setLoan} label="Select the amount you wish to borrow" />
          <SelectInput
            label="Select province of residence"
            onChange={setProvince}
            options={PROVINCES}
            value="BC"
            className="w-full max-w-full !mb-[30px] text-interface-300 font-500"
          />
          <CostSimulatorRadioGroup
            options={[
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
            ]}
            value={payment}
            onChange={setPayment}
            label="Number of payments"
          />
          <CostSimulatorRadioGroup
            options={[
              { label: "Elite", value: "Elite", subtext: "10 first loans" },
              { label: "Prestige", value: "Prestige", subtext: "11th to 20th loan" },
              { label: "VIP", value: "VIP", subtext: "More than 20 loans" },
            ]}
            value={cashback}
            onChange={setCashback}
            onLearnMore={() => navigate("/learn")}
            label="Loyalty program"
          />
        </div>
      </div>
      <div className="cost-simulator--box-container">
        <SummaryBox className="px-[22px] py-[33px] mb-[33px] sm:mb-[22px]">
          <div className="relative w-full h-full">
            <img src={defaultImg} className="absolute right-[-32px] w-[200px]" alt="Product img" />
          </div>
          <h3 className="cost-simulator--heading">Cost Summary</h3>
          <LabeledValue className="mb-[18px]" label="Cost">
            <Price size="medium" amount={cost} />
          </LabeledValue>
          <LabeledValue label="Cashback">
            <Price size="medium" amount={getCashbackAmount(cost, cashback)} />
          </LabeledValue>
          <SummaryDivider />
          <LabeledValue label="Total Cost" size="large">
            <Price size="large" amount={calculateTotalCost(cost, cashback)} />
          </LabeledValue>
        </SummaryBox>
        <CostSimulatorSummaryBanner>
          Apply now and receive your money by <span style={{ whiteSpace: "nowrap" }}>e-Transfer</span> at no extra
          cost.
        </CostSimulatorSummaryBanner>
      </div>
    </CostSimulator>
  );
};
