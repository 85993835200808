import * as React from "react";
import { FullscreenLeft, FullscreenRight } from "@brainfinance/icash-component-library";
import { CustomerServiceProps } from "./customer-service";
import { Box } from "@components/box";
// Images
import backgroundCustomerService from "@images/customer-service.png";
import logo from "@images/icash-symbol-black-sized.svg";

export const CustomerService = (props:React.PropsWithChildren<CustomerServiceProps>) => (
  <Box className="relative flex bg-[#fcfcfc] flex-row flex-wrap sm:mt-[12rem] sm:mb-[3rem] sm:px-[22px] sm:bg-transparent">
    <FullscreenLeft
      logo={logo}
      stars={true}
      style={{ backgroundImage: `url(${props.src})` }}
    >
      <h2 className="global--heading mt-[2.5rem] sm:mt-[1.75rem]">
        Customer service
        <br />
        24/7
      </h2>
    </FullscreenLeft>
    <FullscreenRight>
      {props.children}
    </FullscreenRight>
  </Box>
);

CustomerService.defaultProps = {
  src: backgroundCustomerService,
  children: (<>
    <h3 className="global--subheading mb-[2rem] sm:mb-[22px]">
      Customer service that cares!
    </h3>
    <p className="global--paragraph text-interface-300">
      iCash makes obtaining a loan simple and easy. Our application process is completed 100% online, from the safety and comfort of your home, and, should you have any questions, our team of dedicated Customer Experience Advisors are available 24/7 to help you throughout the process.
    </p>
  </>
  )
}